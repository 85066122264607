
import { defineComponent } from "vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import Location from "@/components/Location.vue";
import { useRouter } from "vue-router";
import { modalController } from "@ionic/vue";
export default defineComponent({
  name: "locationPage",
  components: { Location, HeaderModal },
  data() {
    return {
      router: useRouter(),
      btnIconRight: ["iconduihao"],
      longitude: "",
      latitude: "",
      location: "",
      refreshTime: 0,
    };
  },
  methods: {
    closeModel(r: any) {
      if (r == 4) {
        modalController.dismiss({
          location: this.location,
          longitude: this.longitude,
          latitude: this.latitude,
        });
      } else {
        modalController.dismiss();
      }
    },
    generateLocation(r: any) {
      if (r) {
        this.longitude = r.longitude;
        this.latitude = r.latitude;
        this.location = r.location;
      }
    },
  },
});
