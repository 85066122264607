
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import { useRouter, useRoute } from "vue-router";
import Header from "@/components/Header.vue";
import Utils from "@/utils/Utils";
import moment from "moment";
import regExps from "@/utils/regExps";
import { onDutyService } from "@/services/attendance/onDuty/onDutyService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import locationPage from "@/views/attendance/apply/onduty/locationPage.vue";
export default defineComponent({
  name: "ondutyApply",
  components: {
    Header,
  },
  data() {
    return {
      api: new onDutyService(),
      workflowService: new WorkflowService(),
      router: useRouter(),
      query: useRoute().query,
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      startTime: "", // 开始时间
      startNoon: "AM",
      endTime: "", //结束时间
      endNoon: "PM",
      reason: "",
      location: "",
      longitude: "",
      latitude: "",
    };
  },
  mounted() {},
  methods: {
    //开始日期
    selectStartNoon() {
      let selectOperate: any = document.getElementById("start_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindStartNoon(ev: any) {
      this.startNoon = ev;
    },
    //结束日期
    selectEndNoon() {
      let selectOperate: any = document.getElementById("end_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindEndNoon(ev: any) {
      this.endNoon = ev;
    },
    //选择外出地点
    async changeLocation() {
      const modal = await modalController.create({
        component: locationPage,
        cssClass: "task-progress-update-class",
        componentProps: {
          longitude: this.longitude,
          latitude: this.latitude,
          location: this.location,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.longitude = data.longitude;
        this.latitude = data.latitude;
        this.location = data.location;
      }
    },
    //保存
    saveHandler() {
      if (this.startTime == "") {
        Utils.presentToastWarning("请选择外出开始时间");
        return;
      }
      if (this.endTime == "") {
        Utils.presentToastWarning("请选择外出结束时间");
        return;
      }
      if (this.reason.replace(/\s+/g, "") == "") {
        Utils.presentToastWarning("请输入外出原因");
         return;
      }

      if (regExps.SpecChar.test(this.reason)) {
        Utils.presentToastWarning("外出原因不能输入特殊字符哦！");
        return;
      }
      if (this.location == "") {
        Utils.presentToastWarning("请选择外出地点");
        return;
      }

      this.startTime = moment(this.startTime).format("YYYY-MM-DD 00:00:00");
      this.endTime = moment(this.endTime).format("YYYY-MM-DD 00:00:00");
      if (this.startTime > this.endTime) {
        Utils.presentToastWarning("外出开始时间不能晚于外出结束时间");
        return;
      }
      if (this.startTime == this.endTime) {
        if (this.startNoon == "PM" && this.endNoon == "AM") {
          Utils.presentToastWarning("外出开始时间不能晚于外出结束时间");
          return;
        }
      }
      let that = this;
      Utils.confirmDialog("确认", "请确认是否提交外出申请", function () {
        that.submit();
      });
    },
    submit() {
      let params = {
        startTime: this.startTime,
        startNoon: this.startNoon,
        endTime: this.endTime,
        endNoon: this.endNoon,
        reason: this.reason,
        address: this.location,
        longitude: this.longitude,
        latitude: this.latitude,
      };
      this.api
        .submit(params)
        .then((res: any) => {
          let paramSend = {
            procId: res.procId,
            taskId: res.taskId,
            listReciveVar: [],
            option: "",
          };
          this.workflowService
            .send(paramSend)
            .then((result: any) => {
              if (result) {
                if (result.result) {
                  Utils.presentToast("已成功提交外出申请！");
                  setTimeout(() => {
                    this.router.go(-1);
                  }, 500);
                }
              }
            })
            .catch((ex: any) => {});
        })
        .catch(() => {});
    },
  },
});
